import { NgModule } from '@angular/core';
import { NgbModule , NgbNavModule, NgbDropdownModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe } from '@angular/common';
import {AnalyticsComponent} from "./analytics/analytics.component";
import {PagesRoutingModule} from "./pages-routing.module";
import { BookingsComponent } from './bookings/bookings.component';
import { HotelInformationComponent } from './hotel-information/hotel-information.component';
import {ComponentsModule} from "../components/components.module";
import {FormsModule} from "@angular/forms";
import { BookingListSortableDirective } from '../core/directives/booking-sorting.directive';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { MatIconModule } from '@angular/material/icon';
import { HotelGalleryComponent } from './hotel-gallery/hotel-gallery.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { provideNgxMask } from 'ngx-mask';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {ReactiveFormsModule} from '@angular/forms';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: 'https://httpbin.org/post',
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};
import { ServicesComponent } from './services/services.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '../core/services/language.service';
import { PaymentsComponent } from './payments/payments.component';
import { RoomsComponent } from './rooms/rooms.component';
import { RoomEditComponent } from './rooms/room-edit/room-edit.component';
import { AmenitiesComponent } from './amenities/amenities.component';
import { ThemesComponent } from './themes/themes.component';
import { ActivitiesComponent } from './activities/activities.component';
import { MessagesComponent } from './messages/messages.component';
import { RoomAddComponent } from './rooms/room-add/room-add.component';


// Required for AOT compilation
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AnalyticsComponent,
    BookingsComponent,
    HotelInformationComponent,
    BookingListSortableDirective,
    ContactInformationComponent,
    HotelGalleryComponent,
    ServicesComponent,
    PaymentsComponent,
    RoomsComponent,
    RoomAddComponent,
    RoomEditComponent,
    AmenitiesComponent,
    ThemesComponent,
    ActivitiesComponent,
    MessagesComponent
  ],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    CommonModule,
    PagesRoutingModule,
    NgbPaginationModule,
    ComponentsModule,
    NgbDropdownModule,
    FormsModule,
    NgbModule,
    NgApexchartsModule,
    MatIconModule,
    NgbNavModule,
    DropzoneModule,
    DragDropModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [
    provideNgxMask(),
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    LanguageService,
    DatePipe
  ]
})
export class PagesModule { }
