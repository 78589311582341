import { Component, OnInit } from '@angular/core';
import { RoomService } from '../../../core/services/room.service';
import { FeatureService } from '../../../core/services/feature.service';
import {FormGroup, FormControl} from '@angular/forms';
import { HotelService} from '../../../core/services/hotel.service';
import { Hotel } from '../../../core/interfaces/hotel';
import {Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-room-add',
  templateUrl: './room-add.component.html',
  styleUrl: './room-add.component.scss'
})

export class RoomAddComponent implements OnInit{
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  roomId: any;
  public room: any;
  public hotel: any;
  public hotelAmenities: Array<any> = [];
  selectAll: boolean = false;
  success: boolean = false;
  error: string = '';
  hotelCurrency: string = '';

  roomAddForm = new FormGroup({
    description_de: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description_fr: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description_en: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description_it: new FormControl('', [Validators.required, Validators.minLength(3)]),
    name_de: new FormControl('', [Validators.required, Validators.minLength(3)]),
    name_en: new FormControl('', [Validators.required, Validators.minLength(3)]),
    name_fr: new FormControl('', [Validators.required, Validators.minLength(3)]),
    name_it: new FormControl('', [Validators.required, Validators.minLength(3)]),
    average_room_size: new FormControl('', [Validators.required,]),
    amenities: new FormControl('', [Validators.required, Validators.minLength(3)]),
    amount: new FormControl('', [Validators.required,]),
    max_people_allowed: new FormControl('', [Validators.required]),
    is_family_room: new FormControl(''),
    extra_bed_allowed: new FormControl(''),
    base_price: new FormControl('', [Validators.required]),
    is_breakfast_included: new FormControl(''),
    allow_single_use: new FormControl(''),
    hide: new FormControl(''),
    images: new FormControl('')
  });

  constructor(
    private roomService: RoomService,
    private featureService: FeatureService,
    private hotelService: HotelService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.initializeBreadcrumbs();
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Rooms',link: '/rooms' },
      { label: 'Add room', active: true}
    ];
  }
  ngOnInit(): void {
    this.subscribeToSelectedHotel();
    this.getAmenities();
    this.checkAllAmenitiesStatus();
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.hotelCurrency = this.hotel.currency;
      }
    });
    this.getAmenities();
  }

  getAmenities(): void {
    this.featureService.getFeatures().subscribe((activities: any) => {
      this.hotelAmenities = activities.data.activities;
    });
  }
  get f(){
    return this.roomAddForm.controls;
  }
  onSubmit() {
      const data = this.roomAddForm.value;
      const name = { 'de': data.name_de, 'en':data.name_en, 'fr': data.name_fr, 'it':data.name_it};
      const description = { 'de': data.description_de, 'en':data.description_en, 'fr': data.description_fr, 'it':data.description_it};
      const selectedActivities = this.hotelAmenities.filter(activity => activity.is_active).map(choice => ([choice.id]));
      const features = selectedActivities.map(String)
      const payload = {
        description: description,
        average_room_size: data.average_room_size,
        name: name,
        features: features,
        currency: this.hotelCurrency,
        amount: data.amount,
        max_people_allowed: data.max_people_allowed,
        is_family_room: data.is_family_room ? 1 : 0,
        extra_bed_allowed: data.extra_bed_allowed,
        base_price: data.base_price,
        is_breakfast_included: data.is_breakfast_included ? 1 : 0,
        allow_single_use: data.allow_single_use ? 1 : 0,
        hide: data.hide ? 1 : 0,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }
      console.log('Room Info Payload:', payload);

      this.roomService.addRoom(payload).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
          this.router.navigate(['/rooms']);
        },
        (error) => {
          this.error = 'There was an error saving the room. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
        }
      );
  }

  // Check if all amenities are selected
  checkAllAmenitiesStatus() {
    this.selectAll = this.hotelAmenities.every(amenity => amenity.is_active);
  }
  removeAmenity(index: number): void {
    // Remove the amenity from the hotelAmenities array by index
    this.hotelAmenities.splice(index, 1);
  }

  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.hotelAmenities.forEach(activity => activity.is_active = newStatus);
    this.selectAll = newStatus;
  }

  toggleActivityStatus(activity: any): void {
    activity.is_active = !activity.is_active;
    this.updateSelectAllStatus();
  }

  updateSelectAllStatus(): void {
    if (this.hotelAmenities.length === 0) {
      this.selectAll = false;
    } else {
      this.selectAll = this.hotelAmenities.every(activity => activity.is_active);
    }
  }
}
