import { Component, OnInit, OnDestroy } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { NgForm } from '@angular/forms';
import { FeatureService } from '../../core/services/feature.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit, OnDestroy {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public hotel$: Observable<Hotel | null>;
  public hotelActivities: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  public i: number = 0;
  selectAll: boolean = false;
  noActivities: boolean = false;
  public activities: Array<any> = [];
  success: boolean = false;
  error: string = '';
  private refreshSubscription!: Subscription;

  constructor(
    private hotelService: HotelService,
    private featureService: FeatureService
  ) { 
    this.hotel$ = this.hotelService.selectedHotel$;
  }

  ngOnInit(): void {
    this.hotelService.getSelectedHotelObject().subscribe();
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    this.subscribeToActivities();
    this.checkAllActivitiesStatus();
  }
  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Activities', active: true }
    ];
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.hotelActivities = [];
        this.hotel.hotel_features.forEach((hotelfeature: any) => {
          if (hotelfeature.feature_group_id === 2 && hotelfeature.is_active) {
            this.hotelActivities.push(hotelfeature);
          }
        });
      }
    });
    this.fetchActivities();
  }

  fetchActivities(): void {
    this.featureService.getFeatures().subscribe((activities: any) => {
      this.activities = activities.data.activities;
      this.activities.forEach(activity => {
        const hotelActivity = this.hotelActivities.find(hotelFeature => hotelFeature.id === activity.id);
        if (hotelActivity) {
          activity.is_active = hotelActivity.is_active;
        }
      });
      this.updateSelectAllStatus();
    });
  }
  checkAllActivitiesStatus() {
    this.selectAll = this.activities.every(activity => activity.is_active);
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const selectedActivities = this.activities.filter(activity => activity.is_active).map(choice => ([choice.id]));
      const result = {
        "features": selectedActivities.map(String)
      };
      this.featureService.saveFeatures(result, this.hotel.id, 2).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
        },
        (error) => {
          this.error = 'There was an error saving the activities. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
        }
      );
      form.resetForm();
    }
  }

  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.activities.forEach(activity => activity.is_active = newStatus);
    this.selectAll = newStatus;
  }

  toggleActivityStatus(activity: any): void {
    activity.is_active = !activity.is_active;
    this.updateSelectAllStatus();
  }

  updateSelectAllStatus(): void {
    if (this.activities.length === 0) {
      this.selectAll = false;
    } else {
      this.selectAll = this.activities.every(activity => activity.is_active);
    }
  }

  removeAmenity(activity: any): void {
    this.hotelActivities = this.hotelActivities.filter(a => a !== activity);
    if (this.hotelActivities.length === 0) {
      this.selectAll = false;
      this.noActivities = true;
    } else {
      this.noActivities = false;
    }
  }
  subscribeToActivities(): void {
    this.featureService.getFeatures().subscribe((activities: any) => {
      this.activities = activities.data.activities;
      for (var i = 0; i < this.activities.length; i++) {
        for (var j = 0; j < this.hotelActivities.length; j++) {
          if (this.activities[i].id === this.hotelActivities[j].id) {
            this.activities[i].is_active = this.hotelActivities[j].is_active;
          }
        }
      }
    });
  }
}
