import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HttpClientModule,
  HttpClient,
  withInterceptors,
  provideHttpClient, withInterceptorsFromDi
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {authInterceptor} from "./core/interceptors/auth.interceptor";
import {errorInterceptor} from "./core/interceptors/error.interceptor";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {LayoutComponent} from "./layout/layout.component";
import {ComponentsModule} from "./components/components.module";
import {AccountModule} from "./account/account.module";
import {LanguageService} from "./core/services/language.service";
import { HotelSelectionDialogComponent } from './components/hotel-selection-dialog/hotel-selection-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCardModule} from "@angular/material/card";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {PagesModule} from "./pages/pages.module";
import {HOTEL_SERVICE_TOKEN, HotelService} from "./core/services/hotel.service";
import { UspComponent } from './pages/usp/usp.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HotelSelectionDialogComponent,
    UspComponent
  ],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatDialogModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    HttpClientModule,
    ComponentsModule,
    AccountModule,
    MatButtonModule,
    PagesModule,
    DragDropModule
  ],
  providers: [
    provideHttpClient(
      withInterceptors([authInterceptor, errorInterceptor])
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    LanguageService,
    {provide: HOTEL_SERVICE_TOKEN, useClass: HotelService}
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
