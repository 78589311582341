<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
    <i class="uil uil-exclamation-triangle me-2"></i>
    <span class="ms-2">
      {{ error }}
    </span>
    <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissError()"></button>
</div>

<div *ngIf="successMessage" class="alert alert-success alert-dismissible fade show mt-3">
    <i class="uil uil-check me-2"></i>
    <span class="ms-2">
      {{ successMessage }}
    </span>
    <button type="button" class="btn-close mt-1" aria-label="Close" (click)="dismissSuccess()"></button>
</div>

<div class="container-fluid mt-3">
    <!-- start page title -->
    <app-pagetitle title="Booking Confirmation Message"></app-pagetitle>
    <!-- end page title -->

    <!-- Add message button -->
    <button type="button" class="btn btn-primary submit_btn position-absolute  add-msg-btn cursor-pointer" (click)="addMessage()" [disabled]="permanentMessagesCount > 0">
        Add message &nbsp;<i class="fas fa-plus fa-sm"></i>
    </button>
    <p class="booking-desc-msg mt-4" *ngIf="messages.length === 0">
        This message to the guest will be the part of booking confirmation.
    </p>
    <p class="booking-desc-msg mt-4" *ngIf="messages.length > 0">
        You can add either one permanent or multiple temporary messages.
    </p>

    <!-- ng-container to handle both *ngIf and *ngFor -->
    <ng-container *ngFor="let message of messages; let i = index">
        <div *ngIf="message.isVisible" class="card mt-2">
            <div class="card-body">
                <form #hotelForm="ngForm" (ngSubmit)="onSubmit(hotelForm)">
                    <div class="row mb-3" [ngClass]="{'disabled-msg': message.display === 'temporary' && isTemporaryMessageDisabled}">
                        <label for="roomName" class="form-label col-2">Message</label>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <div class="d-flex mb-2">
                                            <span class="px-3 py-2 text-room-color form-input-bg">Deutsch</span>
                                            <input type="email" class="form-control form-input-bg text-room-desc" [(ngModel)]="message.deutsch" name="deutsch" required [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i">
                                        </div>
                                        <textarea class="form-control form-input-bg textarea-room textarea-msg" [(ngModel)]="message.deutschDescription" name="deutschDescription" placeholder="" required [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i"></textarea>
                                    </div>
                                </div><!-- end col -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <div class="d-flex mb-2">
                                            <span class="px-3 py-2 text-room-color form-input-bg">Français</span>
                                            <input type="email" class="form-control form-input-bg text-room-desc" [(ngModel)]="message.french" name="french" required [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i">
                                        </div>
                                        <textarea class="form-control form-input-bg textarea-room textarea-msg" [(ngModel)]="message.frenchDescription" name="frenchDescription" placeholder="" required [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i"></textarea>
                                    </div>
                                </div><!-- end col -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <div class="d-flex mb-2">
                                            <span class="px-3 py-2 text-room-color form-input-bg">English</span>
                                            <input type="email" class="form-control form-input-bg text-room-desc" [(ngModel)]="message.english" name="english" required [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i">
                                        </div>
                                        <textarea class="form-control form-input-bg textarea-room textarea-msg" [(ngModel)]="message.englishDescription" name="englishDescription" placeholder="" required [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i"></textarea>
                                    </div>
                                </div><!-- end col -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <div class="d-flex mb-2">
                                            <span class="px-3 py-2 text-room-color form-input-bg">Italiano</span>
                                            <input type="email" class="form-control form-input-bg text-room-desc" [(ngModel)]="message.italian" name="italian" required [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i">
                                        </div>
                                        <textarea class="form-control form-input-bg textarea-room textarea-msg" [(ngModel)]="message.italianDescription" name="italianDescription" placeholder="" required [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i"></textarea>
                                    </div>
                                </div><!-- end col -->
                            </div><!-- end row -->
                        </div>
                        <div class="col-1 d-flex justify-content-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="deleteMessage(i)" class="cursor-pointer position-relative delete-msg">
                                <path d="M5.25 5.25L6.1875 20.25C6.23203 21.1167 6.8625 21.75 7.6875 21.75H16.3125C17.1408 21.75 17.7595 21.1167 17.8125 20.25L18.75 5.25" stroke="#F74D4D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.75 5.25H20.25H3.75Z" fill="#F74D4D"/>
                                <path d="M3.75 5.25H20.25" stroke="#F74D4D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                                <path d="M9 5.25V3.375C8.99957 3.22715 9.02837 3.08066 9.08475 2.94397C9.14114 2.80729 9.22399 2.6831 9.32854 2.57854C9.43309 2.47399 9.55728 2.39114 9.69397 2.33476C9.83066 2.27838 9.97714 2.24957 10.125 2.25H13.875C14.0229 2.24957 14.1693 2.27838 14.306 2.33476C14.4427 2.39114 14.5669 2.47399 14.6715 2.57854C14.776 2.6831 14.8589 2.80729 14.9152 2.94397C14.9716 3.08066 15.0004 3.22715 15 3.375V5.25M12 8.25001V18.75M8.625 8.25001L9 18.75M15.375 8.25001L15 18.75" stroke="#F74D4D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <hr class="horizontal-space horiz-pos">
                    <div class="row mb-3">
                        <label for="roomName" class="form-label col-2">Display</label>
                        <div class="col-9">
                            <div class="d-flex">
                                <div class="form-check mb-2">
                                    <input class="form-check-input cursor-pointer" id="permanent-{{i}}" type="radio" name="formRadios{{i}}" [(ngModel)]="message.display" [value]="'permanent'" (change)="checkPermanentMessage('permanent', i)" [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i">
                                    <label class="form-check-label cursor-pointer" for="permanent-{{i}}">
                                        Permanent
                                    </label>
                                </div>
                                <div class="form-check mb-2 ms-3">
                                    <input class="form-check-input cursor-pointer" id="temporary-{{i}}" type="radio" name="formRadios{{i}}" [(ngModel)]="message.display" [value]="'temporary'" (change)="checkPermanentMessage('permanent', i)" [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled && index != i">
                                    <label class="form-check-label cursor-pointer" for="temporary-{{i}}">
                                        Temporary
                                    </label>
                                        <div class="row" *ngIf="message.display === 'temporary'">
                                          <div class="col-sm-12 dateTimeTempotraryMessage">
                                            <!-- Date range picker -->
                                            <div class="input-container">
                                                <input type="text" class="form-control"
                                                    placeholder="Select date range"
                                                    ngxDaterangepickerMd
                                                    [(ngModel)]="message.tempDateRange"
                                                    [locale]="{
                                                        format: 'DD.MM.YYYY',
                                                        firstDay: 1
                                                    }"
                                                    (ngModelChange)="onDateRangeChange($event)"
                                                    [id]="'dynamic-daterange-' + i"
                                                    name="tempDateRange"
                                                    #dateInput
                                                    [ngClass]="{'border-red': isTemporaryMessageOverlap}"
                                                    [disabled]="message.display === 'temporary' && isTemporaryMessageDisabled"
                                                />

                                              <!-- <mat-icon class="calendar-icon text-danger">date_range</mat-icon> -->
                                               <label [for]="'dynamic-daterange-' + i" class="calendar-icon-img" (click)="focusInput(dateInput)">
                                                    <img [src]="calendarIconSrc" alt="calendar-icon">
                                               </label>
                                            </div>
                                          </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </ng-container>

    <!-- Only one Save message button for all messages -->
    <button *ngIf="messages.length > 0" type="button" class="btn btn-primary submit_btn float-md-end cursor-pointer" (click)="saveMessages()" [disabled]="!isFormValid()">
        Save message
    </button>
</div>
