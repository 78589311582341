import { Component, OnInit } from '@angular/core';
import { RoomService } from '../../core/services/room.service';
import { Room } from '../../core/interfaces/room';
import { Router } from '@angular/router';
import { CdkDragDrop, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent implements OnInit {
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  public rooms: any[] = [];
  public hotel: any = {};
  error: string = '';
  success: boolean = false;
  isDropped = false;
  isDropAreaVisible = false;
  showDropIndicator: number | null = null;
  dropAreaIndex: number | null = null;
  draggedRoom: any = null;

  constructor(
    private roomService: RoomService,
    private hotelService: HotelService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initializeBreadcrumbs();
    this.loadRoomsFromLocalStorage(); // Load rooms and visibility from localStorage
    this.subscribeToSelectedHotel();
  }

  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Rooms', link: '/rooms' },
      { label: 'Room Overview', link: '/rooms', active: true }
    ];
  }

  // Fetch selected hotel
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.subscribeToRooms();
      }
    });
  }

  // Fetch all rooms
  subscribeToRooms(): void {
    this.roomService.getAllRooms().subscribe((rooms: Room[] | null) => {
      if (rooms) {
        this.rooms = rooms;
        console.log(this.rooms);
        this.applyRoomVisibilityFromLocalStorage();
      }
    });
  }

  // Load rooms from localStorage
  loadRoomsFromLocalStorage() {
    const roomsFromStorage = JSON.parse(localStorage.getItem('rooms') || '[]');
    const visibilityFromStorage = JSON.parse(localStorage.getItem('roomVisibility') || '{}');

    this.rooms = roomsFromStorage.map((room: any) => {
      // Apply the visibility status from localStorage to each room
      room.hide = visibilityFromStorage[room.id] !== undefined ? visibilityFromStorage[room.id] : room.hide;
      return room;
    });
  }

  // Apply room visibility from localStorage when fetching rooms
  applyRoomVisibilityFromLocalStorage(): void {
    const visibilityFromStorage = JSON.parse(localStorage.getItem('roomVisibility') || '{}');
    this.rooms.forEach((room: any) => {
      room.hide = visibilityFromStorage[room.id] !== undefined ? visibilityFromStorage[room.id] : room.hide;
    });
  }

  onDragMoved(event: CdkDragMove, index: number): void {
    const y = event.pointerPosition.y;
    const roomHeight = event.source.element.nativeElement.offsetHeight;
    const roomTop = event.source.element.nativeElement.getBoundingClientRect().top;
    if (y > roomTop && y < roomTop + roomHeight) {
      this.isDropAreaVisible = true;
      this.dropAreaIndex = index + 1;
    } else {
      this.isDropAreaVisible = false;
    }
  }
  onDragStarted() {
      this.isDropAreaVisible = true;
  }
  onDragEnded() {
      this.isDropAreaVisible = false;
  }
  onDrop(event: any): void {
    // Get the previous index of the room from the event's item data
    const previousIndex = this.rooms.findIndex((room: any) => room === event.item.data);
    const currentIndex = event.currentIndex;
    if (previousIndex !== currentIndex) {
        const movedRoom = this.rooms.splice(previousIndex, 1)[0];
        this.rooms.splice(currentIndex, 0, movedRoom);
    }
    this.isDropAreaVisible = false;
    this.dropAreaIndex = null;
    this.isDropped = true;
    setTimeout(() => {
        this.isDropped = false;
    }, 500);
    this.saveRoomsToLocalStorage();
}

  addRoom(): void {
    this.router.navigate(['/add-room']);
  }

  editRoom(roomId: number): void {
    this.router.navigate(['/room-edit', roomId]);
  }

  // Handle the visibility change (toggle hide/show)
  onHideChange(room: any, event: any): void {
    room.hide = event.target.checked ? 1 : 0;
    this.updateRoomVisibilityInLocalStorage(room);
  }

  // Update room visibility in localStorage
  updateRoomVisibilityInLocalStorage(room: any): void {
    const visibilityData = JSON.parse(localStorage.getItem('roomVisibility') || '{}');
    visibilityData[room.id] = room.hide;
    localStorage.setItem('roomVisibility', JSON.stringify(visibilityData));
  }

  // Save the updated rooms to localStorage
  saveRoomsToLocalStorage(): void {
    localStorage.setItem('rooms', JSON.stringify(this.rooms));
  }

  isRoomHidden(room: any): boolean {
    return room.hide === 1;
  }
}
