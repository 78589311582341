import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoomService } from '../../../core/services/room.service';
import { Room } from '../../../core/interfaces/room';
import { HotelService } from '../../../core/services/hotel.service';
import { FeatureService } from '../../../core/services/feature.service';
import { Hotel } from '../../../core/interfaces/hotel';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-room-edit',
  templateUrl: './room-edit.component.html',
  styleUrl: './room-edit.component.scss'
})
export class RoomEditComponent implements OnInit {
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  roomId: any;
  public room: any; // Define your room type properly
  public hotelAmenities: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  public i:number = 0;
  selectAll:boolean = false;
  amenities: Array<any> = [];
  hotel: any = {};
  success: boolean =false;
  error: string = '';
  hotelCurrency: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private roomService: RoomService,
    private hotelService: HotelService,
    private featureService: FeatureService
  ) {
    this.initializeBreadcrumbs();
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Rooms',link: '/rooms' },
      { label: 'Edit room', active: true}
    ];
  }
  ngOnInit(): void {
    this.roomId = this.route.snapshot.paramMap.get('id');
    this.fetchRoomDetails(this.roomId);
    this.checkAllActivitiesStatus();
    this.subscribeToSelectedHotel();
  }

  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.hotelCurrency = this.hotel.currency;
      }
    });
    this.fetchActivities();
  }
  fetchRoomDetails(id: number): void {
    this.roomService.getRoomDetails(id).subscribe((response) => {
      if (response) {
        this.room = response.data;
        this.hotelAmenities = this.room.features;
      }
    });
  }
  fetchActivities(): void {
    this.featureService.getFeatures().subscribe(amenities => {
      this.amenities = amenities.data.amenities;
      this.amenities.forEach(amenity => {
        const hotelActivity = this.hotelAmenities.find(hotelFeature => hotelFeature.id === amenity.id);
        if (hotelActivity) {
          amenity.is_active = hotelActivity.is_active;
        }
      });
      this.updateSelectAllStatus();
    });
  }
  checkAllActivitiesStatus() {
    this.selectAll = this.amenities.every(amenity => amenity.is_active);
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const data = form.value;
      const name = { 'de': data.name_de, 'en':data.name_en, 'fr': data.name_fr, 'it':data.name_it};
      const description = { 'de': data.description_de, 'en':data.description_en, 'fr': data.description_fr, 'it':data.description_it};
      const selectedActivities = this.amenities.filter(amenity => amenity.is_active).map(choice => ([choice.id]));
      const features = selectedActivities.map(String);
      const payload = {
        description: description,
        average_room_size: data.average_room_size,
        name: name,
        features: features,
        currency: this.hotelCurrency,
        amount: data.amount,
        max_people_allowed: data.max_people_allowed,
        is_family_room: data.is_family_room ? 1 : 0,
        max_extra_bed_allowed: data.max_extra_bed_allowed,
        base_price: data.base_price,
        is_breakfast_included: data.is_breakfast_included ? 1 : 0,
        allow_single_use: data.allow_single_use ? 1 : 0,
        hide: data.hide ? 1 : 0,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }
      console.log('Room Info Payload:', payload);

      this.roomService.editRoom(this.room.id, payload).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
          this.router.navigate(['/rooms']);
        },
        (error) => {
          this.error = 'There was an error saving the room. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
        }
      );
    }
  }
  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.amenities.forEach(amenity => amenity.is_active = newStatus);
    this.selectAll = newStatus;
  }

  toggleActivityStatus(amenity: any): void {
    amenity.is_active = !amenity.is_active;
    this.updateSelectAllStatus();
  }

  updateSelectAllStatus(): void {
    if (this.amenities.length === 0) {
      this.selectAll = false;
    } else {
      this.selectAll = this.amenities.every(amenity => amenity.is_active);
    }
  }
}
