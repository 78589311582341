<div class="container-fluid mt-3">
  <!-- start page title -->
  <div class="d-flex justify-content-between align-items-center">
    <app-pagetitle title="Extra Services"></app-pagetitle>
  </div>
<!-- end page title -->
  <button type="submit" class="btn btn-primary submit_btn position-absolute add-room-btn cursor-pointer add-service-btn" (click)="addService()">Add service &nbsp;<i class="fas fa-plus fa-sm"></i>
  </button>
  <p class="booking-desc-msg mt-3">TO DO: Fill in the guidance / description / best practice</p>
  <div cdkDropList
       class="services-container"
       (cdkDropListDropped)="onDrop($event)"
       [cdkDropListData]="services">
    <div *ngFor="let service of services; let i = index" class="card mt-5" cdkDrag [cdkDragData]="service">
      <div class="card-body">
          <form (ngSubmit)="onSubmit()" #roomForm="ngForm">
              <div class="row mb-3">
                  <label for="roomName" class="form-label col-2">Service Description</label>
                  <div class="col-9">
                      <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <div class="d-flex mb-2">
                                    <label for="language-de" class="px-3 py-2 text-room-color form-input-bg h-40 w-120">Deutsch</label>
                                    <select id="language-de" class="form-select form-input-bg text-room-desc h-40"  required>
                                      <option *ngFor="let service of services_lang" [value]="service.value">{{ service.translations.de }}</option>
                                    </select>
                                </div>
                            </div>
                        </div><!-- end col -->

                        <div class="col-md-6">
                            <div class="mb-3">
                                <div class="d-flex">
                                    <label for="language-fr" class="px-3 py-2 text-room-color form-input-bg h-40 w-120">Français</label>
                                    <select id="language-fr" class="form-select form-input-bg text-room-desc h-40" required>
                                        <option *ngFor="let service of services_lang" [value]="service.value">{{ service.translations.fr }}</option>
                                    </select>
                                </div>
                            </div>
                        </div><!-- end col -->

                        <div class="col-md-6">
                            <div class="mb-3">
                                <div class="d-flex">
                                    <label for="language-en" class="px-3 py-2 text-room-color form-input-bg h-40 w-120">English</label>
                                    <select id="language-en" class="form-select form-input-bg text-room-desc h-40" required>
                                        <option *ngFor="let service of services_lang" [value]="service.value">{{ service.translations.en }}</option>
                                    </select>
                                </div>
                            </div>
                        </div><!-- end col -->

                        <div class="col-md-6">
                            <div class="mb-3">
                                <div class="d-flex">
                                    <label for="language-it" class="px-3 py-2 text-room-color form-input-bg h-40 w-120">Italiano</label>
                                    <select id="language-it" class="form-select form-input-bg text-room-desc h-40" required>
                                        <option *ngFor="let service of services_lang" [value]="service.value">{{ service.translations.it }}</option>
                                    </select>
                                </div>
                            </div>
                        </div><!-- end col -->
                    </div><!-- end row -->

                  </div>
                  <div class="col-1 d-flex align-items-start justify-content-end mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="deleteService(i)" class="cursor-pointer">
                      <g id="trash-outline 1">
                      <path id="Vector" d="M5.25 5.25L6.1875 20.25C6.23203 21.1167 6.8625 21.75 7.6875 21.75H16.3125C17.1408 21.75 17.7595 21.1167 17.8125 20.25L18.75 5.25" stroke="#F74D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <g id="Vector_2">
                      <path d="M3.75 5.25H20.25H3.75Z" fill="#F74D4D"/>
                      <path d="M3.75 5.25H20.25" stroke="#F74D4D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
                      </g>
                      <path id="Vector_3" d="M9 5.25V3.375C8.99957 3.22715 9.02837 3.08066 9.08475 2.94397C9.14114 2.80729 9.22399 2.6831 9.32854 2.57854C9.43309 2.47399 9.55728 2.39114 9.69397 2.33476C9.83066 2.27838 9.97714 2.24957 10.125 2.25H13.875C14.0229 2.24957 14.1693 2.27838 14.306 2.33476C14.4427 2.39114 14.5669 2.47399 14.6715 2.57854C14.776 2.6831 14.8589 2.80729 14.9152 2.94397C14.9716 3.08066 15.0004 3.22715 15 3.375V5.25M12 8.25001V18.75M8.625 8.25001L9 18.75M15.375 8.25001L15 18.75" stroke="#F74D4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                    </svg>
                    <img src="assets/images/drag.png" alt="drag-icon" class="img-fluid ms-3 cursor-pointer">
                  </div>
              </div>
              <hr>
              <div class="row mb-3">
                  <label for="roomName" class="form-label col-2">Service days</label>
                  <div class="col-10 service-days-menu">
                    <div class="row align-items-center">
                      <!-- "All days" checkbox -->
                      <div class="col-auto col-checkbox">
                        <div class="form-check text-room-desc form-room-check" id="categories-pill">
                          <input
                            class="form-check-input cursor-pointer"
                            type="checkbox"
                            [id]="'checkAll'"
                            (change)="toggleSelectAll()"
                            [checked]="selectAll"
                            [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                            style="display: none;">
                          <label
                            class="form-check-label cursor-pointer categories categories-pill"
                            [for]="'checkAll'"
                            [ngClass]="{'active': selectAll, 'inactive': !selectAll}"
                          >
                            All days
                          </label>
                        </div>
                      </div>

                      <!-- Service days checkboxes -->
                      <div class="col-auto col-checkbox" *ngFor="let service_day of timePeriods; let index = index;">
                        <div class="form-check text-room-desc form-room-check" id="categories-pill">
                          <input
                            class="form-check-input cursor-pointer"
                            type="checkbox"
                            [id]="'check' + index"
                            [(checked)]="service_day.is_active"
                            (change)="toggleActivityStatus(service_day)"
                            [ngClass]="{'active': service_day.is_active, 'inactive': !service_day.is_active}"
                            style="display: none;">
                          <label
                            class="form-check-label cursor-pointer categories"
                            [ngClass]="{'active': service_day.is_active, 'inactive': !service_day.is_active}"
                            [for]="'check' + index">
                            {{ service_day }}
                          </label>
                        </div>
                      </div>
                    </div>

                    <!-- "Mandatory" checkbox outside the service days loop -->
                    <div class="col-12 checkbox-mandatory">
                      <div class="form-check form-check-inline text-room-desc">
                        <input class="form-check-input cursor-pointer" type="checkbox" id="formCheck3" [checked]="services[i].time_periods[0].is_mandatory_service">
                        <label class="form-check-label checkbox-label" for="formCheck3">
                          Mandatory
                        </label>
                      </div>
                    </div>
                  </div>
              </div>
              <hr>
              <div class="row">
                  <div class="col-2">
                    <h4 class="card-title">Duration</h4>
                  </div>
                  <div class="col-10">
                    <div class="d-flex">
                        <div class="form-check mb-2">
                            <input class="form-check-input cursor-pointer" id="permanent" type="radio" name="formRadios{{i}}" [value]="'permanent'" [checked]="services[i].time_periods[0].is_permanent_service == 1">
                            <label class="form-check-label cursor-pointer" for="permanent">
                                Permanent
                            </label>
                        </div>
                        <div class="form-check mb-2 temp-margin">
                            <input class="form-check-input cursor-pointer" id="temporary" type="radio" name="formRadios{{i}}" [value]="'temporary'" [checked]="services[i].time_periods[0].is_permanent_service == 0">
                            <label class="form-check-label cursor-pointer" for="temporary">
                                Temporary
                            </label>
                                <div class="row" *ngIf="services[i].time_periods[0].is_permanent_service == 0">
                                  <div class="col-sm-12 dateTimeTempotraryMessage">
                                    <!-- Date range picker -->
                                    <div class="input-container">
                                        <input type="text" class="form-control"
                                            placeholder="Select date range"
                                            ngxDaterangepickerMd
                                            [value]="services[i].time_periods[0].daterange"
                                            (ngModelChange)="onDateRangeChange($event)"
                                            [id]="'dynamic-daterange-' + i"
                                            name="tempDateRange"
                                            #dateInput
                                            [ngClass]="{'border-red': isTemporaryMessageOverlap}"
                                        />
                                      <!-- <mat-icon class="calendar-icon text-danger">date_range</mat-icon> -->
                                      <label [for]="'dynamic-daterange-' + i" class="calendar-icon-img" (click)="focusInput(dateInput)">
                                            <img [src]="calendarIconSrc" alt="calendar-icon">
                                      </label>
                                    </div>
                                  </div>
                                </div>
                        </div>
                    </div>
                </div>
              </div>
              <hr>
              <div class="row">
                  <div class="col-2">
                    <h4 class="card-title">Price</h4>
                  </div>
                  <div class="col-10">
                    <div class="row">
                      <div class="col-5 d-flex">
                        <!-- Input field for Price -->
                        <input
                          type="email"
                          class="form-control form-input-bg text-room-desc text-room-size"
                          id="formrow-email-input"
                          value="{{services[i].time_periods[0].price}} {{services[i].time_periods[0].currency}}"
                          required>

                        <!-- Select field for Price Option -->
                        <select class="form-select form-input-bg ms-3" id="price_option" name="price_option">
                          <!-- Per guest and night -->
                          <option value="per_guest_and_night"
                                  [selected]="hotel?.articles[0].time_periods[0].price_option == 'per_guest_and_night'">
                            Per guest and night
                          </option>

                          <!-- Per guest and stay -->
                          <option value="per_guest_and_stay"
                                  [selected]="hotel?.articles[0].time_periods[0].price_option == 'per_guest_and_stay'">
                            Per guest and stay
                          </option>

                          <!-- Per night -->
                          <option value="per_night"
                                  [selected]="hotel?.articles[0].time_periods[0].price_option == 'per_night'">
                            Per night
                          </option>

                          <!-- Per stay -->
                          <option value="per_stay"
                                  [selected]="hotel?.articles[0].time_periods[0].price_option == 'per_stay'">
                            Per stay
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
              </div>
              <hr>
              <div class="row">
                  <div class="col-2">
                    <h4 class="card-title">Room category</h4>
                  </div>
                  <div class="col-10 room-category-menu">
                    <div class="form-check checkbox-service form-check-inline text-room-desc">
                      <input class="form-check-input cursor-pointer" type="checkbox" id="formCheck1" [checked]="services[i].time_periods[0].allow_all_rooms">
                      <label class="form-check-label checkbox-label cursor-pointer" for="formCheck1">
                          All categories
                      </label>
                    </div>
                    <div class="form-check checkbox-service form-check-inline text-room-desc" *ngFor="let rooms of hotel.rooms">
                      <input class="form-check-input cursor-pointer" type="checkbox" id="formCheck2" [checked]="services[i].time_periods[0].rooms[rooms.id] == 1 ">
                      <label class="form-check-label checkbox-label cursor-pointer" for="formCheck2">
                        {{rooms.name}}
                      </label>
                    </div>
                  </div>
              </div>
              <hr>
              <button type="submit" class="btn btn-primary submit_btn float-md-end cursor-pointer">Save changes</button>
          </form>
      </div><!-- end card-body -->
    </div><!-- end card -->
  </div>
</div>
