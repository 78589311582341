import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeatureService } from '../../core/services/feature.service';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.scss']
})
export class AmenitiesComponent implements OnInit, OnDestroy {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public amenities: any = {};
  public selectAllStatus: { [category: string]: boolean } = {};
  public noAmenities: boolean = false;
  public hotel$: Observable<Hotel | null>;
  success:boolean = false;
  error: string = '';
  hotelAmenities: any = {};
  newArr: any = {};
  selectAll: boolean = false;
  private refreshSubscription!: Subscription;

  constructor(private featureService: FeatureService,
    private hotelService: HotelService
  ) {
    this.hotel$ = this.hotelService.selectedHotel$;
  }

  ngOnInit(): void {
    this.hotelService.getSelectedHotelObject().subscribe();
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    this.subscribeToListOfAmenities();
    this.refreshAmenitiesStateAfterPageLoad();
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Amenities', active: true }
    ];
  }

  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.hotelAmenities = [];
        this.hotel.hotel_features.forEach((hotelfeature: any) => {
          if (hotelfeature.feature_group_id === 1 && hotelfeature.is_active) {
            this.hotelAmenities.push(hotelfeature);
          }
        });
        this.fetchActivities();
        this.subscribeToListOfAmenities();
        this.refreshAmenitiesStateAfterPageLoad();
      }
    });
  }
  refreshAmenitiesStateAfterPageLoad(): void {
    this.featureService.getAmenities().subscribe((amenities: any) => {
      const arr = amenities.data;
      this.amenities = Object.keys(arr).map(key => ({ type: key, value: arr[key] }));
      this.newArr = [];
      this.amenities.forEach((category: any) => {
        category.value.sort((a: any, b: any) => a.sorting - b.sorting);
        category.value.forEach((amenity: any) => {
          const hotelAmenity = this.hotelAmenities.find((hotelfeature: any) => hotelfeature.id === amenity.id);
          if (hotelAmenity) {
            amenity.is_active = hotelAmenity.is_active;
          }
          this.newArr.push(amenity);
        });
      });
      this.updateSelectAllStatusAfterFetch();
    });
  }
  
  updateSelectAllStatusAfterFetch(): void {
    this.amenities.forEach((category: any) => {
      const allSelected = category.value.every((amenity: any) => amenity.is_active);
      this.selectAllStatus[category.type] = allSelected;
    });
  }
  fetchActivities(): void {
    this.featureService.getFeatures().subscribe((amenities: any) => {
      this.newArr = amenities.data.amenities;
      this.newArr.forEach((amenity: any) => {
        const hotelActivity = this.hotelAmenities.find((hotelfeature: any) => hotelfeature.id === amenity.id);
        if (hotelActivity) {
          amenity.is_active = hotelActivity.is_active;
        }
      });
      this.subscribeToListOfAmenities();
    });
  }

  subscribeToListOfAmenities(): void {
    this.featureService.getAmenities().subscribe((amenities: any) => {
      const arr = amenities.data;
      this.amenities = Object.keys(arr).map(key => ({ type: key, value: arr[key] }));
      this.newArr = [];
      this.amenities.forEach((category: any) => {
        category.value.sort((a: any, b: any) => a.sorting - b.sorting);
        category.value.forEach((amenity: any) => {
          const hotelAmenity = this.hotelAmenities.find((hotelfeature: any) => hotelfeature.id === amenity.id);
          if (hotelAmenity) {
            amenity.is_active = hotelAmenity.is_active;
          }
          this.newArr.push(amenity);
        });
      });
    });
  }

  updateAmenitiesState(currentAmenityStatus: any[]): void {
    this.amenities.forEach((category: any) => {
      category.value.forEach((amenity: any) => {
        const savedStatus = currentAmenityStatus.find((status: any) => status.id === amenity.id);
        if (savedStatus) {
          amenity.is_active = savedStatus.is_active;
        }
      });
    });
  
    this.amenities.forEach((category: any) => {
      this.selectAllStatus[category.type] = category.value.every((amenity: any) => amenity.is_active);
    });
  
    this.resetSelectAllStatus();
  }
  

  toggleSelectAll(category: any): void {
    const newStatus = !this.selectAllStatus[category.type];
    category.value.forEach((amenity: any) => amenity.is_active = newStatus);
    this.selectAllStatus[category.type] = newStatus;
  }

  toggleAmenityStatus(amenity: any, category: any): void {
    amenity.is_active = !amenity.is_active;
    this.updateSelectAllStatus(category);
  }

  updateSelectAllStatus(category: any): void {
    const allSelected = category.value.every((amenity: any) => amenity.is_active);
    this.selectAllStatus[category.type] = allSelected; 
  }
  resetSelectAllStatus() {
    this.amenities.forEach((category: any) => {
      this.selectAllStatus[category.type] = category.value.every((amenity: any) => amenity.is_active);
    });
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      const selectedAmenities = this.newArr
        .filter((amenity: any) => amenity.is_active)
        .map((amenity: any) => amenity.id);
      const result = {
        features: selectedAmenities.map(String),
      };
      const currentAmenityStatus = this.newArr.map((amenity: any) => ({
        id: amenity.id,
        is_active: amenity.is_active,
      }));
  
      this.featureService.saveFeatures(result, this.hotel.id, 1).subscribe(
        (response: any) => {
          this.updateAmenitiesState(currentAmenityStatus);
          this.success = true;
          setTimeout(() => {
            this.featureService.getAmenities().subscribe((updatedAmenities: any) => {
              console.log("Updated amenities", updatedAmenities);
              const arr = updatedAmenities.data;
              this.amenities = Object.keys(arr).map(key => ({ type: key, value: arr[key] }));
              this.newArr = [];
              this.amenities.forEach((category: any) => {
                category.value.sort((a: any, b: any) => a.sorting - b.sorting);
                category.value.forEach((amenity: any) => {
                  const savedStatus = currentAmenityStatus.find((status: any) => status.id === amenity.id);
                  if (savedStatus) {
                    amenity.is_active = savedStatus.is_active;
                  }
                  this.newArr.push(amenity);
                });
              });
              this.resetSelectAllStatus();
              this.success = false;
            });
          }, 2000);
        },
        (error) => {
          this.error = 'There was an error saving the themes. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
          console.error("Failed", error);
        }
      );
      form.resetForm();
    } else {
      console.log('Form is invalid');
    }
  }
}
