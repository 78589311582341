import { Component, OnDestroy, OnInit } from '@angular/core';
import { Hotel } from '../../core/interfaces/hotel';
import { HotelService } from '../../core/services/hotel.service';
import { NgForm } from '@angular/forms';
import { RoomService } from '../../core/services/room.service';
import { FeatureService } from '../../core/services/feature.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrl: './themes.component.scss'
})
export class ThemesComponent implements OnInit,OnDestroy {
  breadCrumbItems!: Array<{}>;
  public hotel: any = {};
  public hotel$: Observable<Hotel | null>;
  public hotelThemes: Array<any> = [];
  public hotelfeatures: Array<any> = [];
  public i:number = 0;
  themes: any = {};
  selectAll: boolean = false;
  noThemes: boolean = false;
  success:boolean = false;
  error: string = '';
  private refreshSubscription!: Subscription;

  constructor( private hotelService: HotelService,
    private roomService: RoomService,
    private featureService: FeatureService
  ) {
    this.hotel$ = this.hotelService.selectedHotel$;
  }

  ngOnInit(): void {
    this.hotelService.getSelectedHotelObject().subscribe();
    this.initializeBreadcrumbs();
    this.subscribeToSelectedHotel();
    this.subscribeToThemes();
    this.checkAllThemesStatus();
  }
  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
  initializeBreadcrumbs(): void {
    this.breadCrumbItems = [
      { label: 'Hotel' },
      { label: 'Themes', active: true}
    ];
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        this.hotelThemes = [];
        this.hotel.hotel_features.forEach((hotelfeature: any) => {
          if (hotelfeature.feature_group_id === 4 && hotelfeature.is_active) {
            this.hotelThemes.push(hotelfeature);
          }
        });
        this.fetchActivities();
      }
    });
  }
  fetchActivities(): void {
    this.featureService.getFeatures().subscribe((themes: any) => {
      this.themes = themes.data.themes;
      this.themes.forEach((theme:any) => {
        const hotelActivity = this.hotelThemes.find(hotelFeature => hotelFeature.id === theme.id);
        if (hotelActivity) {
          theme.is_active = hotelActivity.is_active;
        }
      });
      this.updateSelectAllStatus();
    });
  }

  checkAllThemesStatus() {
    this.selectAll = this.themes.every((theme:any) => theme.is_active);
  }

  saveThemes() {
    const selectedThemes = this.hotelThemes.filter(theme => theme.is_active);
    let Result = selectedThemes.map(choice => ({ id: choice.id }));
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const selectedThemes = this.themes.filter((activity: any) => activity.is_active).map((choice: any) => ([choice.id]));
      const result = {
          "features": selectedThemes.map(String)
      };
      this.featureService.saveFeatures(result, this.hotel.id, 4).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
          console.log("Success");
        },
        (error) => {
          this.error = 'There was an error saving the themes. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
          console.error("Failed", error);
        }
      );
      console.log('Form submitted with the following themes:', result);
      form.resetForm();
    } else {
      console.log('Form is invalid');
    }
  }
  toggleSelectAll(): void {
    const newStatus = !this.selectAll;
    this.themes.forEach((theme:any) => theme.is_active = newStatus);
    this.selectAll = newStatus;
  }
  toggleThemeStatus(theme: any): void {
    theme.is_active = !theme.is_active;
    this.updateSelectAllStatus();
  }
  updateSelectAllStatus(): void {
    if (this.themes.length === 0) {
      this.selectAll = false;
    } else {
      this.selectAll = this.themes.every((theme:any) => theme.is_active);
    }
  }
  subscribeToThemes():void {
    this.featureService.getFeatures().subscribe((themes:any) => {
      this.themes = themes.data.themes;
      for (var i=0;i<this.themes.length;i++) {
        for (var j=0;j<this.hotelThemes.length;j++) {
            if (this.themes[i].id === this.hotelThemes[j].id) {
                    this.themes[i].is_active = this.hotelThemes[j].is_active;
            }
          }
      }
    });
  }
}

