<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">
    {{ error }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="error = ''"></button>
</div>
<div *ngIf="success" class="alert alert-success alert-dismissible fade show mt-3">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2">
    Room saved successfully
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="success = false"></button>
</div>

<div class="container-fluid mt-3">
  <!-- start page title -->
  <div class="d-flex justify-content-between align-items-center">
    <app-pagetitle title="Rooms"></app-pagetitle>
    <!-- Breadcrumb navigation positioned to the right -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb justify-content-end mb-0">
        <li *ngFor="let item of breadCrumbItems; let last = last" class="breadcrumb-item" [ngClass]="{ 'active': item.active }">
          <ng-container *ngIf="!last">
            <!-- Non-active breadcrumb items with routerLink -->
            <a [routerLink]="item.link">{{ item.label }}</a>
          </ng-container>
          <ng-container *ngIf="last">
            <!-- Active breadcrumb (the last item) with no link -->
            {{ item.label }}
          </ng-container>
        </li>
      </ol>
    </nav>
  </div>
<!-- end page title -->
  <button type="submit" class="btn btn-primary submit_btn position-absolute add-room-btn cursor-pointer add-rooms-overview" (click)="addRoom()">Add room &nbsp;<i class="fas fa-plus fa-sm"></i>
  </button>
  <div class="mt-5">
      <div class="row mt-3">
        <ng-container *ngFor="let room of rooms; let index = index; let last = last">
          <!-- Card: Image and Content -->
          <div class="col-md-4" cdkDropList [cdkDropListData]="rooms" (cdkDropListDropped)="onDrop($event)">
            <div class="card room-card" cdkDrag
              (cdkDragStarted)="onDragStarted()"
              (cdkDragEnded)="onDragEnded()"
              (cdkDragMoved)="onDragMoved($event, index)"
              id="room-{{ room.id }}"
              >
              <label class="publish-btn position-absolute text-nowrap text-center add-room-btn" [for]="'customSwitchsizemd' + room.id" [ngClass]="room.hide === 0 ? 'bg-publish-online' : ''">
                <svg xmlns="http://www.w3.org/2000/svg" class="position-relative online_text" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <g id="icon">
                  <path id="Vector" d="M6.74478 2.9165C3.67681 2.9165 1.74771 5.80822 1.16666 7.25504C1.74771 8.70187 3.67681 11.5936 6.74478 11.5936C9.81275 11.5936 11.7419 8.70187 12.3229 7.25504C11.7419 5.80822 9.81275 2.9165 6.74478 2.9165ZM3.95572 7.25504C3.95572 6.51534 4.24957 5.80593 4.77262 5.28288C5.29567 4.75983 6.00508 4.46598 6.74478 4.46598C7.48449 4.46598 8.1939 4.75983 8.71695 5.28288C9.24 5.80593 9.53384 6.51534 9.53384 7.25504C9.53384 7.99475 9.24 8.70416 8.71695 9.22721C8.1939 9.75026 7.48449 10.0441 6.74478 10.0441C6.00508 10.0441 5.29567 9.75026 4.77262 9.22721C4.24957 8.70416 3.95572 7.99475 3.95572 7.25504ZM4.88541 7.25504C4.88541 8.28157 5.71825 9.11442 6.74478 9.11442C7.77131 9.11442 8.60416 8.28157 8.60416 7.25504C8.60416 6.22851 7.77131 5.39567 6.74478 5.39567C6.62082 5.39567 6.4988 5.40729 6.38065 5.43053L6.74478 7.25504L4.92027 6.89092C4.89703 7.00906 4.88541 7.13109 4.88541 7.25504Z" fill="#2A3348"/>
                  </g>
                  </svg>
                  {{ room.hide === 0 ? 'Online' : 'Hidden' }}
              </label>
              <div>
                <button type="button" style="margin-top: -2px;" class="btn btn-link p-0 float-end edit-room-icon" (click)="editRoom(room.id)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="edit-2 1">
                    <path id="Vector" d="M16.6169 3.75204C16.8553 3.51361 17.1384 3.32448 17.4499 3.19545C17.7614 3.06641 18.0953 3 18.4325 3C18.7696 3 19.1035 3.06641 19.415 3.19545C19.7265 3.32448 20.0096 3.51361 20.248 3.75204C20.4865 3.99046 20.6756 4.27351 20.8046 4.58503C20.9336 4.89655 21.0001 5.23043 21.0001 5.56761C21.0001 5.90479 20.9336 6.23868 20.8046 6.55019C20.6756 6.86171 20.4865 7.14476 20.248 7.38319L7.99289 19.6383L3.00006 21L4.36174 16.0072L16.6169 3.75204Z" fill="#018EDB" stroke="#018EDB" stroke-width="1.59653" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    </svg>
                </button>
                <img src="assets/images/drag.png" alt="drag-icon" class="img-fluid ms-2 drag-icon-room position-absolute cursor-move">
              </div>
              <div class="row g-0 room-row">
                <div class="col-12">
                  <h2 class="card-title booking-text-color text-nowrap room-view-name" [title]="room.name">{{ room.name }}</h2>
                  <!-- Image and edit button container -->
                  <div class="room-image-container cursor-pointer" (click)="editRoom(room.id)">
                    <!-- The image element -->
                    <img src="{{ room.image }}" class="img-fluid rounded room-image" alt="Room Image 1">

                    <!-- Dark overlay on hover -->
                    <div class="overlay" [ngStyle]="{'background-color': room.hide === 1 ? 'lightgray' : 'transparent', 'opacity': room.hide === 1 ? 0.4 : 0}"></div>

                    <!-- Edit button, centered on the image -->
                    <button type="button" class="btn float-end edit-room-img" (click)="editRoom(room.id)">
                      Edit room
                    </button>
                  </div>
                </div>
                <div class="col-12 d-flex flex-column justify-content-start mt-2">
                  <div class="card-body">
                    <p class="card-text d-flex text-room-desc space-room-desc position-relative"
                    [ngStyle]="{'opacity': room.hide === 1 ? 0.4 : 1}">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon-space-room" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g id="circle-dollar">
                        <path id="Primary" d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM8.56875 4.65313V5.04141C8.83398 5.07422 9.09922 5.14805 9.36172 5.22187C9.41367 5.23555 9.46289 5.24922 9.51484 5.26562C9.8293 5.35313 10.0152 5.67852 9.92773 5.99297C9.84023 6.30742 9.51484 6.49062 9.20039 6.40586C9.15664 6.39492 9.11563 6.38125 9.07188 6.37031C8.88047 6.31562 8.68906 6.26367 8.49492 6.22539C8.13398 6.15703 7.71563 6.18984 7.3793 6.33477C7.07852 6.46602 6.82969 6.7832 7.17148 7.00195C7.43945 7.17422 7.76758 7.26172 8.0793 7.34648C8.14492 7.36289 8.20781 7.38203 8.2707 7.39844C8.69727 7.51875 9.24141 7.67461 9.64883 7.95352C10.1793 8.31719 10.4281 8.90781 10.3105 9.54219C10.1984 10.1547 9.77188 10.5566 9.26055 10.7645C9.04727 10.852 8.81484 10.9066 8.57148 10.934V11.3496C8.57148 11.675 8.30625 11.9402 7.98086 11.9402C7.65547 11.9402 7.39023 11.675 7.39023 11.3496V10.8738C6.99375 10.7836 6.60547 10.6578 6.21992 10.532C5.91094 10.4309 5.74141 10.0945 5.84531 9.78555C5.94922 9.47656 6.28281 9.30703 6.5918 9.41094C6.66016 9.43281 6.72852 9.45742 6.79688 9.4793C7.10586 9.5832 7.42305 9.68984 7.74023 9.7418C8.20508 9.81016 8.57695 9.76914 8.82031 9.6707C9.14844 9.53945 9.3043 9.14844 8.98164 8.92969C8.70547 8.74102 8.36367 8.64805 8.03828 8.56055C7.97539 8.54414 7.91523 8.52773 7.85234 8.50859C7.43945 8.39102 6.92266 8.24609 6.53437 7.99727C6.00117 7.65547 5.73047 7.08672 5.84531 6.45508C5.95469 5.85898 6.41953 5.4625 6.91172 5.24922C7.06211 5.18359 7.22344 5.13164 7.39023 5.09336V4.65313C7.39023 4.32773 7.65547 4.0625 7.98086 4.0625C8.30625 4.0625 8.57148 4.32773 8.57148 4.65313H8.56875Z" fill="#495057"/>
                        </g>
                      </svg>&nbsp;
                      <span class="booking-text-color text-room-desc fw-semibold ms-1" >Price
                      </span> &nbsp;
                      <span>{{ room.base_price }} {{ room.currency }}</span>
                    </p>
                    <p class="card-text d-flex text-room-desc space-room-desc position-relative"
                    [ngStyle]="{'opacity': room.hide === 1 ? 0.4 : 1}">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon-space-room" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g id="bed-empty">
                        <path id="Vector" d="M2.6 5.5998C2.6 4.8572 2.895 4.14501 3.4201 3.61991C3.9452 3.0948 4.65739 2.7998 5.4 2.7998C6.14261 2.7998 6.8548 3.0948 7.3799 3.61991C7.905 4.14501 8.2 4.8572 8.2 5.5998C8.2 6.34241 7.905 7.0546 7.3799 7.5797C6.8548 8.10481 6.14261 8.39981 5.4 8.39981C4.65739 8.39981 3.9452 8.10481 3.4201 7.5797C2.895 7.0546 2.6 6.34241 2.6 5.5998ZM0.5 13.3501C0.5 11.1954 2.24563 9.44981 4.40031 9.44981H6.39969C8.55437 9.44981 10.3 11.1954 10.3 13.3501C10.3 13.7089 10.0091 13.9998 9.65031 13.9998H1.14969C0.790938 13.9998 0.5 13.7089 0.5 13.3501ZM13.8284 13.9998H10.8119C10.93 13.7942 11 13.5557 11 13.2998V13.1248C11 11.797 10.4072 10.6048 9.47313 9.80418C9.52563 9.80199 9.57594 9.79981 9.62844 9.79981H10.9716C12.9206 9.79981 14.5 11.3792 14.5 13.3282C14.5 13.7001 14.1981 13.9998 13.8284 13.9998ZM9.95 8.39981C9.27187 8.39981 8.65938 8.12418 8.21531 7.68012C8.64625 7.09824 8.9 6.37855 8.9 5.5998C8.9 5.01355 8.75562 4.46012 8.49969 3.97449C8.90656 3.67699 9.4075 3.4998 9.95 3.4998C11.3041 3.4998 12.4 4.59574 12.4 5.9498C12.4 7.30387 11.3041 8.39981 9.95 8.39981Z" fill="#495057"/>
                        </g>
                      </svg>&nbsp;
                      <span class="booking-text-color text-room-desc fw-semibold ms-1">
                        Basic occupancy
                      </span> &nbsp;
                      <span>{{ room.amount }}</span>
                    </p>
                    <div class="form-check form-switch form-switch-md mb-2 text-room-desc position-absolute hide-room-view" dir="ltr">
                      <input
                        type="checkbox"
                        class="form-check-input position-relative form-hide-view-switch cursor-pointer"
                        [id]="'customSwitchsizemd' + room.id"
                        [(ngModel)]="room.hide"
                        [checked]="isRoomHidden(room)"
                        (change)="onHideChange(room, $event)">
                        <label class="form-check-label mt-3 cursor-pointer label-switch-text position-relative fw-normal" [for]="'customSwitchsizemd' + room.id">Hide room</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Drop Area between rooms -->
          <ng-container *ngIf="isDropAreaVisible && dropAreaIndex === index + 1">
            <div class="col-md-4 drop-area" [ngClass]="{ 'dotted-border': !isDropped }" cdkDropList (cdkDropListDropped)="onDrop($event)">
              <p>+ Drop area</p>
            </div>
          </ng-container>
        </ng-container>
      </div>
  </div>
</div>
