import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { CookiesService } from './cookies.service';
import { Hotel } from '../interfaces/hotel';

@Injectable({
  providedIn: 'root'
})
export class UspService {
  private selectedHotelKey = 'selectedHotel';
  private selectedHotelSubject = new BehaviorSubject<Hotel | null>(null);
  selectedHotel$ = this.selectedHotelSubject.asObservable();

  constructor(
    private http: HttpService,
    private cookieService: CookiesService
  ) { }

  getSelectedHotel(): string | null {
    return this.cookieService.get(this.selectedHotelKey);
  }
  getUspDetails(id: number): Observable<any> {
    const selectedHotelId = this.getSelectedHotel();
    return this.http.get(`hotelsV2/usp/${selectedHotelId}`);
  }
}
