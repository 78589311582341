import { Component, OnInit } from '@angular/core';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { ContactInformationService } from '../../core/services/contact-information.service';
import { NgForm } from '@angular/forms';
import { CountryService } from '../../core/services/country.service';
import { Country } from '../../core/interfaces/country';
import { CONTACT } from './contact';
import { ContactInfo } from '../../core/interfaces/contact-info';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from '../../core/services/language.service';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrl: './contact-information.component.scss'
})
export class ContactInformationComponent implements OnInit {
// bread crumb items
breadCrumbItems!: Array<{}>;
maleTranslation: string = '';
femaleTranslation: string = '';
noBookingRequestsLabel: string = '';
public hotel: any = {};
public hotel_contact: any = {};
radioSelection: string = '';
radioOptions: any = {};
showLightCard: boolean = false;
countries: any;
contactInfo: ContactInfo[] = [];
success:boolean = false;
error: string = '';
selectedLanguage: number | null = null;
languages: { id: number, name: string }[] = [
  { id: 0, name: 'German' },
  { id: 1, name: 'English' },
  { id: 2, name: 'French' },
  { id: 3, name: 'Italian' },
];

// BehaviorSubject to manage language changes
private currentLang$ = new BehaviorSubject<string>('en');

constructor(private hotelService: HotelService,
            private contactInformationService: ContactInformationService,
            private countryService: CountryService,
            public translate: TranslateService,
            public cookieService: CookieService,
            private languageService: LanguageService ) {

  // Set initial language based on cookie
  const lang = this.cookieService.get('selectedLanguage') || 'en';
  this.languageService.setLanguage(lang); // Set the initial language
  this.translate.setDefaultLang(lang);
  this.translate.use(lang);
  this.contactInfo = CONTACT;
  this.radioOptions = this.contactInfo
  .sort((a, b) => {
    if (a.label === 'CONTACTINFO.FEMALE') {
      return -1;
    } else if (b.label === 'CONTACTINFO.FEMALE') {
      return 1;
    }
    return 0
  })
  .map((contact) => ({
    id: contact.id,
    gender: contact.label === 'CONTACTINFO.MALE' ? 'male' : 'female',
    label: contact.label,
    no_booking_requests: contact.no_booking_requests === 'CONTACTINFO.NO_BOOKING_REQUESTS' 
      ? this.translate.instant('CONTACTINFO.NO_BOOKING_REQUESTS') 
      : ''
  }));

  // Subscribe to language changes
  this.languageService.currentLang$.subscribe((lang) => {
    this.translate.use(lang);
    this.updateTranslations();
  });
}
ngOnInit(): void {
    this.subscribeToSelectedHotel();
    this.fetchByCountries();
    this.contactInformationService.getShowLightCard().subscribe(show => {
      this.showLightCard = show;
    });
    this.initiliazeBreadCrumbs();
  }
  updateTranslations() {
    this.maleTranslation = this.translate.instant('CONTACTINFO.MALE');
    this.femaleTranslation = this.translate.instant('CONTACTINFO.FEMALE');
    this.noBookingRequestsLabel = this.translate.instant('CONTACTINFO.NO_BOOKING_REQUESTS');
  }
  initiliazeBreadCrumbs(){
   /**
   * BreadCrumb
   */
    this.breadCrumbItems = [
      { label: 'Hotels' },
      { label: 'Contact information', active: true }
    ];
  }
  fetchByCountries(){
    this.countryService.getAllCountries().subscribe(
      (data: Country[]) => {
        this.countries = data;
        this.fetchRegionsByHotelCountry();
      },
      (error) => {
        console.error('Error fetching countries:', error);
      }
    );
  }
  fetchRegionsByHotelCountry(): void {
    if (this.hotel.country_id) {
      const countryIndex = this.countries.findIndex((country:any) => country.id === this.hotel.country_id);
    }
  }
  subscribeToSelectedHotel(): void {
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      if (hotel) {
        this.hotel = hotel;
        console.log(this.hotel);
        this.hotel_contact = this.hotel.hotel_contact;
        this.fetchRegionsByHotelCountry();
      }
    });
  }
  onSubmit(form: NgForm) {
    if (form.valid) {
      console.log(this.hotel);

      // Construct the payload according to the required structure
      const payload = {
          id: this.hotel.hotel_contact.id,
          hotel_id: this.hotel.id,
          gender: form.value.gender,
          first_name: form.value.first_name,
          last_name: form.value.last_name,
          role: form.value.role,
          email: form.value.email,
          general_email: form.value.general_email,
          booking_email: form.value.booking_email,
          phone_number: form.value.phone_number,
          phone_requests: form.value.phone_requests,
          number: form.value.number,
          booking_language_id: form.value.booking_language_id,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
      };
      this.contactInformationService.saveHotelContact(this.hotel.id, payload).subscribe(
        (response: any) => {
          this.success = true;
          setTimeout(() => {
            this.error = '';
          }, 2000);
          console.log("Success");
        },
        (error) => {
          this.error = 'There was an error saving the contact information. Please try again.';
          setTimeout(() => {
            this.error = '';
            this.success = false;
          }, 2000);
          console.error("Failed", error);
        }
      );
    } else {
      this.error = 'Please fill out all required fields.';
      setTimeout(() => {
        this.error = '';
        this.success = false;
      }, 2000);
      console.log('Form is invalid');
    }
  }
    // You can set the error message when you catch any error in the database interaction
    handleError(errorMessage: string) {
      this.error = errorMessage;
    }
    getLanguageName(languageId: number): string {
      const selectedLanguage = this.languages.find(lang => lang.id === languageId);
      return selectedLanguage ? selectedLanguage.name : 'Unknown';
    }
    onHideChange(hotel: any, event: Event) {
      // Get the checkbox element from the event
      const checkbox = event.target as HTMLInputElement;
      
      // Check if the checkbox is checked
      if (checkbox.checked) {
        console.log(`Hotel Contact ${hotel.name} is now hidden.`);
        hotel.phone_requests = 1;
        console.log(`Hotel Contact ${hotel.name} is now visible.`);
        hotel.phone_requests = 0;
      }
    }
    
    isPhoneRequestsHidden(room: any): boolean {
      return room.phone_requests === 1;
    }
}
