import { Component, TemplateRef, ViewChild } from '@angular/core';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { UspService } from '../../core/services/usp.service';

@Component({
  selector: 'app-usp',
  templateUrl: './usp.component.html',
  styleUrl: './usp.component.scss'
})
export class UspComponent {
  @ViewChild('uspLimitModal') uspLimitModal!: TemplateRef<any>;
  services: any[] = [];
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  cards: Array<any> = [];
  public hotel: any = {};
  private cardIndexToDelete: number | null = null;
  successMessage = '';
  isError: string | boolean | null = null;
  errorMessage: string = '';
  messages: any[] = [];
  usps: any[] = [];
  isDataFetched: boolean = false;
  languages = [
    { key: 'de', label: 'Deutsch' },
    { key: 'en', label: 'English' },
    { key: 'fr', label: 'Français' },
    { key: 'it', label: 'Italiano' }
  ];

  constructor(
    private hotelService: HotelService,
    private modalService: NgbModal,
    private router: Router,
    private uspservice: UspService
  ) { }

  ngOnInit():void{
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      this.hotel = hotel;
      this.isDataFetched = true;
    });
    this.uspservice.getUspDetails(this.hotel.id).subscribe((usp: any) => {
      this.usps = usp.data;
    });
    // BreadCrumbs
    this.breadCrumbItems = [
      { label: 'USPs',link: '/usp' },
      { label: 'USPs Overview',link: '/usp', active: true }
    ];
    this.loadCards();
    if (this.cards.length === 0) {
      this.addCard();
    }
  }

  addCard() {
    this.saveCards();
  }

  // Method to add a new service (i.e., a new card)
  addService() {
    const newService = {
      id: this.usps.length + 1,
      description: '',
      language: { de: '', fr: '', en: '', it: '' },
      isActive: true,
      price: 0.00,
      priceOption: 'per_guest_and_night',
      categories: ['All categories']
    };
    this.usps.push(newService);
    if (this.usps.length >= 4) {
      const modalRef = this.modalService.open(this.uspLimitModal, {
        windowClass: 'modalOpenUSP'
      });
      modalRef.result.then(
        (result) => {
          console.log('Modal closed with:', result);
        },
        (reason) => {
          console.log('Modal dismissed with:', reason);
        }
      );
    }
  }

  // Method to load cards from localStorage
  private loadCards(): void {
    const cards = localStorage.getItem('cards');
    if (cards) {
      this.cards = JSON.parse(cards);
    }
  }

  // Method to save cards to localStorage
  private saveCards(): void {
    localStorage.setItem('cards', JSON.stringify(this.cards));
  }

  // Method to open the delete confirmation modal
  openDeleteModal(staticDataModal:any): void {
    this.cardIndexToDelete = staticDataModal;
    this.modalService.open(staticDataModal, { backdrop: 'static', keyboard: false, centered: true });
  }

  // Method to confirm the deletion of a card
  confirmDelete(modal: any): void {
    if (this.cardIndexToDelete !== null) {
      this.cards.splice(this.cardIndexToDelete, 1);
      this.saveCards();
    }
    this.cardIndexToDelete = null;
    modal.close();
  }

  // DragDrop Functionality
  onDrop(event: CdkDragDrop<any[]>) {
    const previousIndex = this.services.findIndex(service => service === event.item.data);
    const currentIndex = event.currentIndex;
    this.moveItemInArray(this.services, previousIndex, currentIndex);
  }

  // Utility function to reorder array
  moveItemInArray(array: any[], fromIndex: number, toIndex: number) {
    const [removed] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, removed);
  }

  // Toggle method for checkbox
  toggleApplicableToAllRooms(event: any) {
  }
  onSubmit() {
    this.router.navigate(['/services']);
  }
  focusInput(dateInput: HTMLInputElement): void {
    dateInput.focus();
  }

  toggleActivityStatus(activity: any) {
    activity.is_active = !activity.is_active;
  }
  toggleSelectAll(): void {

  }
  // Handle date range change
  onDateRangeChange(event: any): void {
    if (event?.startDate && event?.endDate) {
      const dateRange = {
        startDate: new Date(event.startDate).toISOString(),
        endDate: new Date(event.endDate).toISOString()
      };
      localStorage.setItem('selectedDateRange', JSON.stringify(dateRange));
    }
  }
  deleteService(index: number): void {
    this.usps.splice(index, 1);
  }

  // Function to check for empty fields and validate
  validateServices(): boolean {
    let isValid = true;
    let missingFields: string[] = [];
    for (let service of this.usps) {
      if (!service.descriptionDe) missingFields.push('Deutsch');
      if (!service.descriptionFr) missingFields.push('Français');
      if (!service.descriptionEn) missingFields.push('English');
      if (!service.descriptionIt) missingFields.push('Italiano');
    }
    if (missingFields.length > 0) {
      isValid = false;
      this.errorMessage = `Please fill in the following fields: ${missingFields.join(', ')}`;
      this.isError = true;
    } else {
      this.isError = false;
    }
    return isValid;
  }

  // Save changes
  saveChanges() {
    if (this.validateServices()) {
      this.isError = null;
    } else {
        this.isError = 'Some fields are empty. Please fill in the gaps or remove the whole USP.';
    }
  }
  dismissError(): void {
    this.isError = null;
  }
}
