import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotelService } from '../../core/services/hotel.service';
import { Hotel } from '../../core/interfaces/hotel';
import { Router } from '@angular/router';
interface Service {
  display: string;  // 'permanent' or 'temporary'
  tempDateRange: string | null;  // Date range for temporary, or null if permanent
}

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent {
  services: any[] = [];
  breadCrumbItems!: Array<{label: string; link?: string; active?: boolean}>;
  cards: Array<any> = [];
  public hotel: any = {};
  private cardIndexToDelete: number | null = null;
  selectAll: boolean = false;
  dynamicId = 'example-daterange-picker-' + Math.random().toString(36).substring(2, 15);
  calendarIconSrc = 'assets/images/calendar.png';
  isTemporaryMessageOverlap = false;
  isTemporaryMessageDisabled = false;
  messages: any[] = [];
  timePeriods: any[] = [];
  message = {
    display: 'permanent',
    tempDateRange: { startDate: '', endDate: '' }
  };
  priceOptions = [
    { name: 'per guest_night' },
    { name: 'per guest_stay' },
    { name: 'per night' },
    { name: 'per stay' }
  ];
  // Toggle the display of the card form
  showForm: boolean = false;
  daysOfWeek = [
    { id: 'mon', key: 'monday', label: 'Monday' },
    { id: 'tue', key: 'tuesday', label: 'Tuesday' },
    { id: 'wed', key: 'wednesday', label: 'Wednesday' },
    { id: 'thu', key: 'thursday', label: 'Thursday' },
    { id: 'fri', key: 'friday', label: 'Friday' },
    { id: 'sat', key: 'saturday', label: 'Saturday' },
    { id: 'sun', key: 'sunday', label: 'Sunday' }
  ];
  service_days = [
    { name: 'Monday', is_active: true },
    { name: 'Tuesday', is_active: true },
    { name: 'Wednesday', is_active: true },
    { name: 'Thursday', is_active: true },
    { name: 'Friday', is_active: true },
    { name: 'Saturday', is_active: false },
    { name: 'Sunday', is_active: false },
  ];
  languages = [
    { key: 'de', label: 'Deutsch' },
    { key: 'en', label: 'English' },
    { key: 'fr', label: 'Français' },
    { key: 'it', label: 'Italiano' }
  ];
  // Array of services
  service: Service[] = [
    {
      display: 'permanent',
      tempDateRange: null,
    },
    {
      display: 'temporary',
      tempDateRange: '2025-01-01 to 2025-01-10',
    },
    {
      display: 'permanent',
      tempDateRange: null,
    },
  ];


  // Array of button configurations
  roomCategories = [
    { id: 1, label: 'Double room comfort', doubleRoomComfort: true },
    { id: 2, label: 'Doubleroom Junior Suite', doubleRoomComfort: true }
  ];
  services_lang = [
    {
      value: 'fruehstueck',
      translations: {
        de: 'Frühstück',
        fr: 'Petit Déjeuner',
        en: 'Breakfast',
        it: 'Colazione'
      }
    },
    {
      value: 'halbpension',
      translations: {
        de: 'Halbpension',
        fr: 'Demi Pension',
        en: 'Half Board',
        it: 'Mezza Pensione'
      }
    },
    {
      value: 'parkplatz',
      translations: {
        de: 'Parkplatz',
        fr: 'Parking',
        en: 'Parking',
        it: 'Parcheggio'
      }
    },
    {
      value: 'kurtaxe',
      translations: {
        de: 'Kurtaxe',
        fr: 'Taxe de Séjour',
        en: 'Tourist Tax',
        it: 'Tassa di Soggiorno'
      }
    }
  ];
  // Property to track the checkbox state
  isServiceApplicableToAllRooms: boolean = false;

  constructor(
    private hotelService: HotelService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit():void{
    this.hotelService.selectedHotel$.subscribe((hotel: Hotel | null) => {
      this.hotel = hotel;
      this.services = this.hotel['articles'];
      for (var i = 0; i < this.services.length; i++) {
         this.timePeriods[i] = this.services[i].time_periods[0];
         this.timePeriods[i].daterange = this.formatDateRange(this.timePeriods[i].daterange['start_date'], this.timePeriods[i].daterange['end_date']);
      }
      console.log(this.timePeriods);
      console.log(this.hotel);
    });
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      { label: 'Services',link: '/services' },
      { label: 'Service Overview',link: '/services', active: true }
    ];
        this.loadCards();
        if (this.cards.length === 0) {
          this.addCard();
        }
  }

  addCard() {
    this.cards.push({
      descriptions: this.languages.reduce((acc, lang) => ({ ...acc, [lang.key]: '' }), {}),
      dateRange: '',
      isPermanentService: false,
      dealDays: this.daysOfWeek.reduce((acc, day) => ({ ...acc, [day.key]: false }), {}),
      price: '0.00',
      priceOption: this.priceOptions[0].name,
      isMandatory: false,
      allowAllRooms: false
    });
    this.saveCards();
  }
  // Method to add a new service (i.e., a new card)
  addService() {
    const newService = {
      id: this.services.length + 1,
      description: '',
      language: { de: '', fr: '', en: '', it: '' },
      isActive: true,
      price: 0.00,
      priceOption: 'per_guest_and_night',
      categories: ['All categories']
    };
    this.services.push(newService);
  }

  // Method to load cards from localStorage
  private loadCards(): void {
    const cards = localStorage.getItem('cards');
    if (cards) {
      this.cards = JSON.parse(cards);
    }
  }

  // Function to handle changes on the 'permanent' radio button
  checkPermanentMessage(type: string, index: number) {
    console.log(`Radio button selected: ${type} for service index ${index}`);
  }

  // Method to save cards to localStorage
  private saveCards(): void {
    localStorage.setItem('cards', JSON.stringify(this.cards));
  }

  // Method to open the delete confirmation modal
  openDeleteModal(staticDataModal:any): void {
    this.cardIndexToDelete = staticDataModal;
    this.modalService.open(staticDataModal, { backdrop: 'static', keyboard: false, centered: true });
  }

  // Method to confirm the deletion of a card
  confirmDelete(modal: any): void {
    if (this.cardIndexToDelete !== null) {
      this.cards.splice(this.cardIndexToDelete, 1);
      this.saveCards();
    }
    this.cardIndexToDelete = null;
    modal.close();
  }

  // DragDrop Functionality
  onDrop(event: CdkDragDrop<any[]>) {
    const previousIndex = this.services.findIndex(service => service === event.item.data);
    const currentIndex = event.currentIndex;
    this.moveItemInArray(this.services, previousIndex, currentIndex);
  }

  // Utility function to reorder array
  moveItemInArray(array: any[], fromIndex: number, toIndex: number) {
    const [removed] = array.splice(fromIndex, 1);
    array.splice(toIndex, 0, removed);
  }

  // Toggle method for checkbox
  toggleApplicableToAllRooms(event: any) {
    this.isServiceApplicableToAllRooms = event.target.checked;
    this.roomCategories.forEach(roomCategory => roomCategory.doubleRoomComfort = this.isServiceApplicableToAllRooms);
  }
  onSubmit() {
    this.router.navigate(['/services']);
  }
  focusInput(dateInput: HTMLInputElement): void {
    dateInput.focus();
  }
  toggleActivityStatus(activity: any) {
    activity.is_active = !activity.is_active;
  }
  toggleSelectAll(): void {
  const newStatus = !this.selectAll;
    this.service_days.forEach(service_day => service_day.is_active = newStatus);
    this.selectAll = newStatus;
  }
  // Handle date range change
  onDateRangeChange(event: any): void {
    if (event?.startDate && event?.endDate) {
      const dateRange = {
        startDate: new Date(event.startDate).toISOString(),
        endDate: new Date(event.endDate).toISOString()
      };
      localStorage.setItem('selectedDateRange', JSON.stringify(dateRange));
    }
  }
  // Format date range as MM/DD/YYYY - MM/DD/YYYY
  formatDateRange(startD: any, endD: any): string {
    const startDate = new Date(startD);
    const endDate = new Date(endD);
    return `${this.formatDate(startDate)} - ${this.formatDate(endDate)}`;
  }

  // Format a Date object as MM/DD/YYYY
  formatDate(date: Date): string {
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }

  deleteService(index: number): void {
    this.services.splice(index, 1);
  }
}
