<div *ngIf="error" class="alert alert-danger alert-dismissible fade show mt-3">
  <i class="uil uil-exclamation-triangle me-2"></i>
  <span class="ms-2">
    {{ error }}
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="error = ''"></button>
</div>
<div *ngIf="success" class="alert alert-success alert-dismissible fade show mt-3">
  <i class="uil uil-check me-2"></i>
  <span class="ms-2">
    Amenities saved successfully
  </span>
  <button type="button" class="btn-close" aria-label="Close" (click)="success = false"></button>
</div>
<!-- Page Title -->
<app-pagetitle title="Hotel Amenities" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

<p class="amenities-desc-msg text-muted mt-4 ms-2">
  Specify the equipment of this hotel. It will be displayed on the hotel page.
  Any amenities missing? Contact us on +41 848 711 717 or hotelsupport&#64;hotelcard.ch
</p>

<form class="gx-3 gy-2 align-items-center" (ngSubmit)="onSubmit(hotelAmenitiesForm)" #hotelAmenitiesForm="ngForm">
  <div class="card mt-2">
    <div class="card-body">
      <!-- Loop through categories -->
      <div class="row" *ngFor="let category of amenities; let lastCategory = last">
        <!-- Category Title -->
        <div class="col-2">
          <h4 class="card-title mt-1">{{ category.type }}</h4>
        </div>
        <!-- Amenities Column -->
        <div class="col-10">
          <div class="row">
            <!-- Display message when no amenities exist -->
            <div *ngIf="noAmenities" class="no-activities">
              <p class="text-center">No Amenities here...</p>
            </div>
            <!-- Select All Column -->
            <div class="col-12 col-md-2 d-flex justify-content-end" *ngIf="category.value.length > 0">
              <div class="form-check text-room-desc form-room-check" id="categories-pill">
                <input
                  class="form-check-input cursor-pointer"
                  type="checkbox"
                  [id]="'checkAll' + category.type"
                  (change)="toggleSelectAll(category)"
                  [checked]="selectAllStatus[category.type]"
                  [ngClass]="{'active': selectAllStatus[category.type], 'inactive': !selectAllStatus[category.type]}"
                  style="display: none;">
                <label
                  class="form-check-label cursor-pointer categories"
                  [for]="'checkAll' + category.type"
                  [ngClass]="{'active': selectAllStatus[category.type], 'inactive': !selectAllStatus[category.type]}">
                  Select all
                </label>
              </div>
            </div>
            <!-- Amenities List Column -->
            <div class="col-12 col-md-10">
              <div class="row">
                <div class="col-auto" *ngFor="let amenity of category.value; let index = index;">
                  <div class="form-check text-room-desc form-room-check" id="categories-pill">
                      <input
                        class="form-check-input cursor-pointer"
                        type="checkbox"
                        [id]="'check' + amenity.id"
                        [(checked)]="amenity.is_active"
                        (change)="toggleAmenityStatus(amenity, category)"
                        [ngClass]="{'active': amenity.is_active, 'inactive': !amenity.is_active}"
                        style="display: none;">
                      <label
                        class="form-check-label cursor-pointer categories"
                        [ngClass]="{'active': amenity.is_active, 'inactive': !amenity.is_active}"
                        [for]="'check' + amenity.id">
                        {{ amenity.name }}
                      </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <hr *ngIf="!lastCategory" class="horizontal-space">
        </div>
      </div>
    </div><!-- end card-body -->
  </div><!-- end card -->

  <div class="col-12">
    <button type="submit" class="btn btn-primary submit_btn float-md-end cursor-pointer" [disabled]="hotelAmenitiesForm.invalid">Save changes</button>
  </div>
</form>
